import React, { useState } from "react"
import TicketSelection from "../../components/pages/Main/TicketSelection"
import EntryTickets from "../../components/pages/Main/EntryTickets"
import { FaTicketAlt, FaCoins } from "react-icons/fa"
import { MdAttractions } from "react-icons/md" // From Material Icons
import { Link, useNavigate } from "react-router-dom"
const Topup = () => {
  const [anyDayCount, setAnyDayCount] = useState(0)
  const [anyDayCount2, setAnyDayCount2] = useState(0)
  const [freeTicketCount, setFreeTicketCount] = useState(0)
  const [activeBonus, setActiveBonus] = useState({ price: 0, desc: "" })
  const bonuses = [
    {
      price: 150,
      desc: "160 Points (incl. 12% Bonus Carnaval Points)",
    },
    {
      price: 250,
      desc: "285 Points (incl. 20% Bonus Carnaval Points)",
    },
    {
      price: 350,
      desc: "415 Points (incl. 25% Bonus Carnaval Points)",
    },
    {
      price: 550,
      desc: "705 Points (incl. 35% Bonus Carnaval Points)",
    },
    {
      price: 1050,
      desc: "1400 Points (incl. 40% Bonus Carnaval Points)",
    },
  ]

  const handleBonusClick = (bonus) => {
    if (activeBonus?.price === bonus.price) {
      setActiveBonus({ price: 0, desc: "" }); // Deselect if already selected
    } else {
      setActiveBonus(bonus); // Select if not already selected
    }
  };

  const  navigate = useNavigate()

  const [btn_content, set_btn_content] = useState('Proceed to Checkout')
  return (
    <div className="w-full flex flex-col">
      <div className="fixed flex justify-between bottom-0 w-full p-4 md:px-48 md:p-6 rev-grad z-max">
        <span className="text-white md:text-lg">
          AED{" "}
          <span className="text-xl md:text-2xl font-semibold">
            {(anyDayCount * 25 + anyDayCount2 * 55 + activeBonus.price).toFixed(
              2
            )}
          </span>
        </span>
        <button 
        onClick={()=>{
            set_btn_content(<div className="inputLoader"></div>)
            setTimeout(()=>{
                navigate('/checkout')
            },2000)
        }}
        className="active rounded-full md:text-xl min-w-32 text-white py-2 px-4">
          {btn_content}
        </button>
      </div>
        <div className="w-full md:h-[500px] h-[220px] md:pb-[300px] pb-[180px] relative">
            <img className='w-full h-full block absolute top-0 left-0 object-cover' src="/assets/img/park-bg.jpg" alt="" />
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-60 z-40"></div>
            <div className="relative z-50 flex p-4 w-full md:justify-center md:gap-x-16 md:mt-[60px]  gap-x-4 px-6">
                <Link to='/' className="flex gap-y-2 flex-col items-center px-1 text-3xl md:text-5xl">
                <FaTicketAlt color='white'  />
                        <span className='  font- md:text-lg text-sm text-white '>Park Entry</span>
                </Link>
                <Link to='/attractions'  className="flex text-3xl md:text-5xl gap-y-2 flex-col items-center px-1 pb-1   border-whtie">
                <MdAttractions color='white' />
                        <span className='  font- text-sm text-white  md:text-lg  '>Attractions</span>
                </Link>
                <Link to='/topup' className="flex gap-y-2 text-3xl md:text-5xl flex-col items-center px-1 pb-1   border-whtie">
                <FaCoins color='#F26F22'  />
                        <span className='  font- text-sm  md:text-lg text-[#F26F22] pb-1  border-b-2 border-[#F26F22]'>WonderPass Top-up</span>
                </Link>
            </div>
        </div>

      <div className="w-full  bg-[#20295C] flex flex-col">
        <div className="w-full flex flex-col md:items-center z-50 -mt-[130px] md:-mt-[300px]">
          <div className="w-full  rounded-t-2xl bg-white max-w-6xl ">
            <div class="flex flex-col px-4 pt-4 md:flex-row md:p-8">
                <div className="flex flex-col md:w-1/2">

            <span className="w-full  text-[#20295C] md:text-2xl text-lg font-semibold">
            Top-up your Wonder Pass card now
              </span>
              <span className="text-[10px] md:text-sm md:mt-2 text-[#333333] mb-4">
              Please enter your 10 digit card number here
              </span>
                </div>
              <div className="flex flex-col w-full md:w-1/2">

              <div className="w-full px-4 rounded mb-2 py-3 items-center border flex justify-between">
                <span className=" text-[#F26F22] font-medium">Add new card</span>
                
<svg xmlns="http://www.w3.org/2000/svg" width="14.284" height="8.142" viewBox="0 0 14.284 8.142">
  <g id="arrow-point-to-right" transform="translate(14.284) rotate(90)">
    <path id="Path_67" data-name="Path 67" d="M7.849,7.849,1.708,13.991A1,1,0,0,1,.293,12.576L5.727,7.142.293,1.708A1,1,0,0,1,1.708.293L7.849,6.435a1,1,0,0,1,0,1.414Z" transform="translate(0 0)" fill="#f37021"/>
  </g>
</svg>

              </div>
              <input className="border w-full px-4 py-3 mb-12" placeholder="Card number" type="text" />
              </div>
            </div>
            <div className="w-full bg-[#E3F1FF] py-6 md:py-8 flex flex-col items-center shadow">
              <span className="w-full px-2 text-[#20295C] text-center text-lg md:text-2xl font-semibold">
                Buy your multi-use points package now
              </span>
              <span className="text-[10px] text-[#333333] mb-4 md:mb-6 md:text-base">
                Get exciting bonus offers!{" "}
              </span>

              <div className="w-full flex overflow-x-scroll md:overflow-x-hidden md:justify-center gap-x-2 px-4">
                {bonuses.map((bonus, index) => (
                  <div
                    key={index}
                    className={`flex bg-white flex-shrink-0 w-1/2 md:w-fit shadow border-2 p-4 items-center flex-col rounded-lg cursor-pointer ${
                      activeBonus?.price === bonus.price
                        ? "  border-orange-500"
                        : " border-white"
                    }`}
                    onClick={() => handleBonusClick(bonus)}
                  >
                    <span className="text-[#A6A4A9] text-sm">
                      AED{" "}
                      <span
                        className={` text-xl font-semibold ${
                          activeBonus?.price === bonus.price
                            ? "  text-[#F26F22]"
                            : " text-black"
                        }`}
                      >
                        {bonus.price.toFixed(2)}
                      </span>
                    </span>
                    <span className="text-xs text-[#A6A4A9] w-32 mt-3 text-center">
                      {bonus.desc}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full bg-white flex flex-col p-6 md:p-8">
              <span className="w-full px-2 text-[#20295C] text-center text-lg md:text-2xl font-semibold">
                Buy individual attraction tickets
              </span>
              <span className="text-[10px] text-[#333333] mb-4 md:mb-6 md:text-base md:text-center">
                Discover our wonderful world of extraordinary attractions and
                book the perfect one to enjoy on your next visit.{" "}
              </span>

              <div className="w-full flex flex-col">
                <span className="text-lg font-medium text-black mt-6 mb-2">
                  Cyber city stunt show
                </span>
                <div className="flex flex-col md:flex-row md:gap-x-8 md:pr-28">
                  <img
                    className="w-full rounded-2xl h-[160px] object-cover md:w-1/3 md:h-fit md:aspect-square"
                    src="https://gvprodbsgwebmobile.azureedge.net/gvprodbstgconwebmobile/files/specials-products/aqua%20action%20%284%29.jpg"
                    alt=""
                  />
                  <div className="flex flex-col">


                  
                  <span className="w-full  text-[#20295C] md:font-semibold  text-lg font-medium">
                    Regular Seat at Cyber City Stunt Show
                  </span>
                  <span className="text-xs text-[#4E4953] md:text-base">
                    Welcome to Cyber City, a dazzling metropolis where sleep is
                    a luxury and action never ceases. Gleaming skyscrapers
                    illuminate the night, and pristine streets buzz with energy,
                    all maintained by hefty taxes that cater only to the
                    ultra-wealthy.
                  </span>
                  <div className="w-full flex justify-between mt-3">
                    <div className="flex flex-col">
                      <p className="text-sm md:text-base">Any Day Entry Ticket</p>
                      <span className="font-semibold text-base text-black">
                        AED 25.00
                        <span className="text-[#A09EA2]  font-normal">
                          {" "}
                          per person
                        </span>
                      </span>
                    </div>
                    <div className="flex justify-between items-center ">
                      <div className="flex items-center space-x-2">
                        <button
                          className="rounded-full border border-[#F26F22] text-[#F26F22] font-bold py-1 px-3 "
                          onClick={() =>
                            setAnyDayCount(Math.max(anyDayCount - 1, 0))
                          }
                        >
                          -
                        </button>
                        <span>{anyDayCount}</span>
                        <button
                          className="rounded-full border border-[#F26F22] text-[#F26F22]  font-bold py-1 px-3 "
                          onClick={() => setAnyDayCount(anyDayCount + 1)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>



                    <div className="w-full border-b my-4 mt-8"></div>



              <div className="w-full flex flex-col">
                <span className="text-lg font-medium text-black mt-6 mb-2">
                  Neon Galaxy X Challenge Zone
                </span>
                <div className="flex flex-col md:flex-row md:gap-x-8 md:pr-28">
                  <img
                    className="w-full rounded-2xl h-[160px] object-cover md:w-1/3 md:h-fit md:aspect-square"
                    src="https://gvprodbsgwebmobile.azureedge.net/gvprodbstgconwebmobile/files/specials-products/Neon%203%27_0.JPG"
                  alt=""
                  />
                  <div className="flex flex-col">


                  
                  <span className="w-full  text-[#20295C] md:font-semibold  text-lg font-medium">
                  Full Day Access To All Zones Offer
                  </span>
                  <span className="text-xs text-[#4E4953] md:text-base">
                  Gain full-day access to all zones, where you can play the
                  games available in each zone.
                  </span>
                  <div className="w-full flex justify-between mt-3">
                    <div className="flex flex-col">
                      <p className="text-sm md:text-base">Any Day Entry Ticket</p>
                      <span className="font-semibold text-base text-black">
                        AED 55.00
                        <span className="text-[#A09EA2]  font-normal">
                          {" "}
                          per person
                        </span>
                      </span>
                    </div>
                    <div className="flex justify-between items-center ">
                      <div className="flex items-center space-x-2">
                        <button
                          className="rounded-full border border-[#F26F22] text-[#F26F22] font-bold py-1 px-3 "
                          onClick={() =>
                            setAnyDayCount2(Math.max(anyDayCount2 - 1, 0))
                          }
                        >
                          -
                        </button>
                        <span>{anyDayCount2}</span>
                        <button
                          className="rounded-full border border-[#F26F22] text-[#F26F22]  font-bold py-1 px-3 "
                          onClick={() => setAnyDayCount2(anyDayCount2 + 1)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Topup
