import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import "world-flags-sprite/stylesheets/flags32.css";

const countries = [
    { label: "United Arab Emirates (الإمارات العربية المتحدة)", value: "+971", code: "ae" },
    { label: "United States", value: "+1", code: "us" },
    { label: "United Kingdom", value: "+44", code: "gb" }
    // Add more countries here
  ];
  

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #D1D5DB",
      borderRadius: "0.375rem",
      padding: "0.3rem 0.5rem", // Adjust input padding for smaller size
 // Smaller font size for input

      boxShadow: "none",

      "&:hover": {
        borderColor: "#F97316",
      },
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: "1rem", // Larger font size for dropdown
      padding: "0.5rem", // Add padding inside dropdown
      zIndex: 1000, // Ensure dropdown appears on top
      width: "350px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#F97316" : "white",
      color: state.isFocused ? "white" : "black",
      padding: "10px 20px", // Make dropdown options larger
      cursor: "pointer",
      
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      fontSize: "0.875rem", // Smaller font for selected value
    }),
  };
  

const TicketForm = () => {
    const navigate = useNavigate()

    const [sbmt_btn_content, set_sbmt_btn_content] = useState('Next')
  const [formData, setFormData] = useState({
    email: "",
    phoneNumber: "",
    countryCode: "+971",
    firstName: "",
    lastName: "",
    agreeTerms: false,
    agreeMarketing: false,
  });

  const [selectedCountry, setSelectedCountry] = useState({ label: "United Arab Emirates (الإمارات العربية المتحدة)", value: "+971", code: "ae" });

  const handleChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    console.log("Selected country:", selectedOption);
  };

  const formatOptionLabel = ({ label, code, value }) => (
    <div className="flex items-center">
      <img
        src={`https://flagcdn.com/w40/${code}.png`}
        alt={`${label} flag`}
        className="mr-2"
        style={{ width: "20px", height: "15px" }}
      />
      <span>{label}</span>
      <span className="ml-auto text-black">{value}</span>
    </div>
  );

  const formatSingleValue = ({ code, value }) => (
    <div className="flex items-center">
      <img
        src={`https://flagcdn.com/w40/${code}.png`}
        alt="Selected flag"
        style={{ width: "20px", height: "15px", marginRight: "8px" }}
      />
      <span>{value}</span>
    </div>
  );


  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data Submitted:", formData);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white p-6 rounded-2xl shadow-md max-w-lg mx-auto md:max-w-6xl md:grid md:grid-cols-2 md:gap-6  md:p-16 md:py-10"
    >
      <p className="text-sm mb-6 md:mb-2 md:text-lg md:col-span-2">
        Enter your details below, your ticketing information will be sent to
        this email.
      </p>

      {/* Email */}
      <div className="mb-4">
        <label htmlFor="email" className="block text-sm font-medium mb-1">
          Email<span className="text-red-500">*</span>
        </label>
        <input
          type="email"
          name="email"
          id="email"
          value={formData.email}
          onChange={handleInputChange}
          className="w-full border border-gray-300 rounded-md p-2 focus:ring focus:ring-orange-500"
          
        />
      </div>

      {/* Phone Number */}
      <div className="mb-4">
        <label htmlFor="phoneNumber" className="block text-sm font-medium mb-1">
          Phone Number
        </label>
        <div className="flex gap-x-3">
        <Select
      options={countries}
      value={selectedCountry}
      onChange={handleChange}
      styles={customStyles}
      formatOptionLabel={formatOptionLabel}
      placeholder="Select a country"
    className="w-60"
      components={{
        SingleValue: ({ data }) => formatSingleValue(data),
      }}
    />
          <input
            type="tel"
            name="phoneNumber"
            id="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            className="w-full  border-t border-b border-r border-gray-300 rounded-r-md p-2 focus:ring focus:ring-orange-500"
          />
        </div>
      </div>

      {/* First Name */}
      <div className="mb-4">
        <label htmlFor="firstName" className="block text-sm font-medium mb-1">
          First Name<span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          name="firstName"
          id="firstName"
          value={formData.firstName}
          onChange={handleInputChange}
          className="w-full border border-gray-300 rounded-md p-2 focus:ring focus:ring-orange-500"
          
        />
      </div>

      {/* Last Name */}
      <div className="mb-4">
        <label htmlFor="lastName" className="block text-sm font-medium mb-1">
          Last Name<span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          name="lastName"
          id="lastName"
          value={formData.lastName}
          onChange={handleInputChange}
          className="w-full border border-gray-300 flex-shrink-0 rounded-md p-2 focus:ring focus:ring-orange-500"
          
        />
      </div>

      {/* Terms & Conditions */}
      <div className="mb-4 md:col-span-2">
        <label className="inline-flex items-start">
          <input
            type="checkbox"
            name="agreeTerms"
            checked={formData.agreeTerms}
            onChange={handleInputChange}
            className="w-4 h-4 border-gray-300 flex-shrink-0 rounded text-orange-500 focus:ring focus:ring-orange-500"
            
          />
          <span className="ml-2 text-sm md:text-base">
            I agree to the{" "}
            <a href="#" className="text-orange-500 underline">
              Terms & Conditions
            </a>{" "}
            of Global Village.
          </span>
        </label>
      </div>

      {/* Marketing Consent */}
      <div className="mb-6 md:col-span-2">
        <label className="inline-flex items-start">
          <input
            type="checkbox"
            name="agreeMarketing"
            checked={formData.agreeMarketing}
            onChange={handleInputChange}
            className="w-4 h-4 border-gray-300 rounded text-orange-500 focus:ring focus:ring-orange-500"
          />
          <span className="ml-2 text-sm md:w-2/3 md:text-base">
            I would like to receive personalized offers, events, and promotions
            from DH Entertainment attractions. Further detail, including how we
            tailor our marketing communications, can be found in{" "}
            <a href="#" className="text-orange-500 underline">
              Privacy Policy
            </a>
            .
          </span>
        </label>
      </div>

      {/* Submit Button */}
      <div className="w-full flex justify-center md:col-span-2">

      <button
      onClick={()=>{
          set_sbmt_btn_content(<div className="inputLoader"></div>)
          setTimeout(()=>{
            navigate('/payment')
        },3000)
    }}
    type="submit"
    className=" px-12 rounded-full active text-white font-bold py-2  hover:bg-orange-600 transition"
    >
        {sbmt_btn_content}
      </button>
          </div>
    </form>
  );
};

export default TicketForm;
