const EntryTickets = () => {
    return (
      <div className=" text-white p-2 rounded-md max-w-5xl mx-auto">
        {/* Entry Tickets Section */}
        <div className="bg-white text-[#20295C] rounded-2xl md:rounded-3xl p-6 shadow-md">
          <h1 className="text-lg font-bold md:text-3xl">Entry Tickets</h1>
          <p className="text-sm md:text-base text-[#4E4953] mt-4">
            Any day tickets are for AED 30 and weekday tickets are for AED 25.
            Weekday tickets are valid (Sun-Thu) except public holidays. Park
            entry tickets are valid for a single entry during Season 29 (Tuesdays
            are exclusively reserved for Families, Couples, and Ladies).
          </p>
          <p className="text-sm md:text-base text-[#4E4953] mt-4">Please view the details for more information.</p>
  
          {/* Image Section */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
            <img
              src="/assets/img/ticket1.jpg"
              alt="Ticket example 1"
              className="rounded-lg shadow-md w-full h-20 md:h-40 object-cover"
            />
            <img
              src="/assets/img/ticket2.jpg"
              alt="Ticket example 2"
              className="rounded-lg shadow-md w-full h-20 md:h-40 object-cover md:col-span-2"
            />
          </div>
          <h2 className="text-xl font-bold mt-6 md:text-2xl md:mt-8">What is included?</h2>
          <ul className="list-disc mt-4 ml-6 text-sm md:text-base space-y-2">
            <li>
              Park entry, valid for a single entry during Season 29 (please note
              Tuesdays are family days).
            </li>
            <li>
              Access to 30 country pavilions where you can tour unique cultural
              treasures including new additions like Oman and Qatar.
            </li>
            <li>
              Access to an authentic Emirati heritage area, taking you back in
              time.
            </li>
            <li>Access to 200+ shows every day.</li>
            <li>
              Access to the Kids’ Theatre with plenty of fun for the little ones,
              starring all their favourite characters and much more.
            </li>
          </ul>
          <h2 className="text-lg font-bold mt-6 md:text-xl md:mt-10">Some simple rules to keep it fun for everyone...</h2>
          <ul className="mt-4 md:mt-8 space-y-4">
            <li className="flex items-center space-x-4">
              <img
                src="https://gvprodbsgwebmobile.azureedge.net/gvprodbstgconwebmobile/image-description/modest-dressing%403x.png"
                alt="icon"
                className="h-6 w-6"
              />
              <span className="text-sm md:text-lg">Please dress modestly</span>
            </li>
            <li className="flex items-center space-x-4">
              <img
                src="https://gvprodbsgwebmobile.azureedge.net/gvprodbstgconwebmobile/image-description/no-pets%403x.png"
                alt="icon"
                className="h-6 w-6"
              />
              <span className="text-sm md:text-lg">Pets are not permitted</span>
            </li>
            <li className="flex items-center space-x-4">
              <img
                src="https://gvprodbsgwebmobile.azureedge.net/gvprodbstgconwebmobile/image-description/public_affection%201.png"
                alt="icon"
                className="h-6 w-6"
              />
              <span className="text-sm md:text-lg">Avoid public display of affection</span>
            </li>
            <li className="flex items-center space-x-4">
              <img
                src="https://gvprodbsgwebmobile.azureedge.net/gvprodbstgconwebmobile/image-description/filming%201.png"
                alt="icon"
                className="h-6 w-6"
              />
              <span className="text-sm md:text-lg">Filming is not permitted without a permit</span>
            </li>
            <li className="flex items-center space-x-4">
              <img
                src="https://gvprodbsgwebmobile.azureedge.net/gvprodbstgconwebmobile/image-description/roller_skatess_1.png"
                alt="icon"
                className="h-6 w-6"
              />
              <span className="text-sm md:text-lg">
                No Segways, scooters, bicycles, hoverboards, skateboards, roller
                skates
              </span>
            </li>
          </ul>
          <h2 className="text-lg font-bold mt-6 md:mt-8">Terms & Conditions</h2>
          <p className="text-sm md:text-lg mt-2 md:mb-12 ">
            Read <span href="#" className="text-orange-500 ">Ticket Terms & Conditions</span> and{" "}
            <span href="#" className="text-orange-500   ">Park Terms & Conditions</span>
          </p>
        </div>
  
        {/* What is included Section */}


  
        {/* Rules Section */}


  
        {/* Terms & Conditions Section */}

      </div>
    );
  };
  
  export default EntryTickets;
  