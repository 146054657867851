import React from 'react'
import TicketSelection from '../../components/pages/Main/TicketSelection'
import EntryTickets from '../../components/pages/Main/EntryTickets'
import { FaTicketAlt, FaCoins } from 'react-icons/fa';
import { MdAttractions } from 'react-icons/md'; // From Material Icons
import { Link } from 'react-router-dom';
const Main = () => {
  return (
    <div className='w-full flex flex-col'>
        <div className="w-full md:h-[500px] h-[220px] md:pb-[300px] pb-[180px] relative">
            <img className='w-full h-full block absolute top-0 left-0 object-cover' src="/assets/img/park-bg.jpg" alt="" />
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-60 z-40"></div>
            <div className="relative z-50 flex p-4 w-full md:justify-center md:gap-x-16 md:mt-[60px]  gap-x-4 px-6">
                <Link to='/' className="flex gap-y-2 flex-col items-center px-1 text-3xl md:text-5xl">
                <FaTicketAlt color='#F26F22'  />
                        <span className='pb-1  border-b-2 border-[#F26F22]  font- md:text-lg text-sm  text-[#F26F22]'>Park Entry</span>
                </Link>
                <Link to='/attractions'  className="flex text-3xl md:text-5xl gap-y-2 flex-col items-center px-1 pb-1   border-whtie">
                <MdAttractions color='white' />
                        <span className='  font- text-sm  md:text-lg  text-white'>Attractions</span>
                </Link>
                <Link to='/topup' className="flex gap-y-2 text-3xl md:text-5xl flex-col items-center px-1 pb-1   border-whtie">
                <FaCoins color='white'  />
                        <span className='  font- text-sm  md:text-lg  text-white'>WonderPass Top-up</span>
                </Link>
            </div>
        </div>



        <div className="w-full p-2 bg-[#20295C] flex flex-col items-center">
            <div className="w-full flex flex-col z-50 md:-mt-[325px] max-w-5xl -mt-[130px]">

            <div className="w-full p-4  ">
                <TicketSelection />
            </div>
            <EntryTickets />
            </div>
        </div>
    </div>
  )
}

export default Main