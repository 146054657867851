
import StatusMessage from './StatusMessage'
import './authLoader.css'

const AuthLoading = () => {


    return(
        <div className="flex items-center justify-center w-full h-96 my-44">
            <div className="flex flex-col items-center">
                <span class="loader mb-6"></span>
                <StatusMessage />
            </div>
        </div>
    )
}

export default AuthLoading