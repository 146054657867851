const Footer = () => {
    return (
      <footer className="bg-[#373F6D] text-white  ">
        {/* Season Details */}
        <div className="mb-6 p-4 md:px-48 md:flex md:items-start">
          <div className=" text-lg font-bold flex items-center  space-x-2">

          <svg className=" flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="19.04" height="19.261" viewBox="0 0 19.04 19.261">
  <g id="noun_timing_1689598" transform="translate(-9.305 -6.641)">
    <path id="Path_67000" data-name="Path 67000" d="M25.492,9.409a9.561,9.561,0,0,0-6.121-2.751,11.856,11.856,0,0,0-1.6.022,9.455,9.455,0,0,0-5.621,2.6c-.128.123-.246.258-.364.389a3.406,3.406,0,0,0-.692.834A9.256,9.256,0,0,0,9.315,15.73c0,.3.17.534.4.532a.492.492,0,0,0,.448-.492V15.7a8.4,8.4,0,0,1,2.906-5.528A8.227,8.227,0,0,1,18.857,8.2a8.062,8.062,0,0,1-.071,16.117A8.215,8.215,0,0,1,13.019,22.3a8.387,8.387,0,0,1-2.845-5.555h0a.5.5,0,0,0-.46-.482c-.231,0-.411.229-.409.529v.084a9.29,9.29,0,0,0,2.9,6.431A9.468,9.468,0,0,0,18.911,25.9a9.634,9.634,0,0,0,7.42-15.527C25.673,9.569,26.086,10.132,25.492,9.409Z" fill="#fcb514"/>
    <path id="Path_67001" data-name="Path 67001" d="M43.411,41.874a1.167,1.167,0,0,1-1.307-.852.834.834,0,0,0-.441-.074c-.231,0-.418.049-.431.123v.017A2.148,2.148,0,0,0,41.8,42.7a2.336,2.336,0,0,0,1.676.758,2.446,2.446,0,0,0,1.789-.709,2.525,2.525,0,0,0-.017-3.625,2.461,2.461,0,0,0-1.1-.6,5.515,5.515,0,0,0-.342-3.5,2.227,2.227,0,0,0-.847,0h0a5.513,5.513,0,0,0-.315,3.6,2.272,2.272,0,0,0-.856.593,2.144,2.144,0,0,0-.544,1.612c0,.071.19.126.418.123s.418-.037.428-.076h0a1.174,1.174,0,0,1,1.324-.844.943.943,0,0,1,0,1.856Z" transform="translate(-24.813 -24.445)" fill="#fcb514"/>
  </g>
</svg>

            <h2>Season 29</h2>
          </div>
          <div className=" md:flex-col md:mx-6">

          <p className="mt-2 text-sm text-[#FCB514] font-bold ">
            16 Oct 2024 - 11 May 2025
            <br />
            <span className="font-bold">Sun - Wed:</span> 4:00pm - 12:00am
            <br />
            <span className="font-bold">Thu - Sat:</span> 4:00pm - 01:00am
          </p>
          <p className="">
            Tuesdays (Except those falling on a public holiday) are exclusively
            reserved for Families, Couples, and Ladies.
          </p>
          </div>
          <div className="mt-4 flex items-center space-x-2 text-white font-bold cursor-pointer">

          <svg className=" flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
  <g id="Group_6644" data-name="Group 6644" transform="translate(-1344 -52)">
    <g id="Ellipse_1511" data-name="Ellipse 1511" transform="translate(1344 52)" fill="none" stroke="#fcb514" stroke-width="2">
      <circle cx="13" cy="13" r="13" stroke="none"/>
      <circle cx="13" cy="13" r="12" fill="none"/>
    </g>
    <path id="Path_9088" data-name="Path 9088" d="M877.98,438.374l2.336-.293c.307-.039.614-.075.92-.116a.557.557,0,1,0-.09-1.111c-.646.042-1.29.1-1.935.156s-1.27.124-1.906.154a26.664,26.664,0,0,1-3.732-.181c-.507-.047-1.014-.1-1.522-.126a.548.548,0,0,0-.567.511.536.536,0,0,0,.471.6c.494.071.989.136,1.484.2l1.516.207c0,.04.007.073.007.105v3.113a.734.734,0,0,1-.027.182c-.448,1.7-.9,3.4-1.339,5.1a.721.721,0,0,0,.044.424.616.616,0,0,0,.629.354.583.583,0,0,0,.536-.458q.61-2,1.218-4.008c.079-.261.159-.522.238-.778h.5c.015.041.03.08.043.12l.6,1.78c.323.96.644,1.92.971,2.878a.61.61,0,0,0,.847.385.634.634,0,0,0,.314-.81q-.754-2.5-1.507-5.009a1,1,0,0,1-.041-.28v-2.927Zm-2.566-2.844a1.09,1.09,0,1,0,1.1-1.079,1.09,1.09,0,0,0-1.1,1.079Z" transform="translate(480.518 -376.45)" fill="#fcb514" stroke="#fcb514" stroke-width="0.3"/>
  </g>
</svg>

            <span>Accessibility settings</span>
          </div>
        </div>
  
        {/* Quick Links and Corporate Section */}
        <div className="grid p-4 bg-[#2D376A] md:px-48 md:content-center md:flex md:w-full md:justify-evenly grid-cols-2 gap-6 text-sm md:text-xl">
          {/* Quick Links */}
          <div>
            <h3 className="text-orange-500 font-bold mb-2 md:text-2xl">Quick Links</h3>
            <ul className="space-y-1">
              <li>
                <a href="#" className="hover:underline">
                  About Us
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  Blogs
                </a>
              </li>
            </ul>
          </div>
  
          {/* Corporate Links */}
          <div>
            <h3 className="text-orange-500 font-bold mb-2 md:text-2xl">Corporate</h3>
            <ul className="space-y-1">
              <li>
                <a href="#" className="hover:underline">
                  Partner Portal Access
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  For Business
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  For Travel Trade
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  For Media
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    );
  };
  
  export default Footer;
  