import { useState } from "react";
import { useNavigate } from "react-router-dom";

const TicketSelection = () => {
  const [weekdayTicket, setWeekdayTicket] = useState(false);
  const [anyDayCount, setAnyDayCount] = useState(0);
  const [freeTicketCount, setFreeTicketCount] = useState(0);
  const navigate = useNavigate()

  const [isOn, setIsOn] = useState(false);

  const toggleSwitch = () => setIsOn(!isOn);

  const [sbmt_btn_content, set_sbmt_btn_content] = useState('Proceed to Checkout')
  return (
    <div className=" text-white ">
      {/* Weekday Ticket Toggle */}
      <div className=" text-[#20295C] mb-4 md:rounded-3xl bg-white rounded-2xl p-4 md:p-8">
        <div className="flex flex-col md:flex-row md:justify-between md:items-end">
        <h2 className="text-lg font-bold md:text-3xl">Select Entry Tickets</h2>
        <p className="text-sm mt-1 md:text-lg text-black">Starting from <span className="font-medium">AED 25</span><span className="text-[#A09EA2]"> per person</span></p>

        </div>

        <div className="flex justify-between items-center mt-4 md:mt-6">
          <span className="text-[12px] text-black pr-10 md:text-lg">
            Choose weekday ticket for AED 25

              (Valid from Sunday to Thursday excluding public holidays)

          </span>
          <label className="relative inline-flex items-center cursor-pointer">
          <div
      className={`w-14 h-7 flex items-center rounded-full p-1 cursor-pointer ${
        isOn ? 'bg-orange-500' : 'bg-gray-400'
      }`}
      onClick={toggleSwitch}
    >
      <div
        className={`bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ${
          isOn ? 'translate-x-6' : 'translate-x-0'
        }`}
      ></div>
    </div>
          </label>
        </div>
      <div className=" text-[#20295C]   mb-4 ">
        <div className="flex  flex-col ">
          <div className="w-full flex justify-between md:mt-6 mt-3">
            <div className="flex flex-col">


          <p className="text-sm font-bold md:text-xl">Any Day Entry Ticket</p>
          <span className="font-semibold text-base md:text-lg text-black">AED 25<span className="text-[#A09EA2] font-normal"> per person</span></span>
            </div>
        <div className="flex justify-between items-center ">
          <div className="flex items-center space-x-2 md:space-x-3">
            <button
              className="rounded-full border border-[#F26F22] text-[#F26F22] font-medium py-1 px-3 md:px-1 md:py-1  md:text-2xl"
              onClick={() => setAnyDayCount(Math.max(anyDayCount - 1, 0))}
            >
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path></svg>
            
            </button>
            <span className="md:text-xl  ">{anyDayCount}</span>
            <button
              className="rounded-full border border-[#F26F22] text-[#F26F22] font-medium py-1 px-3 md:px-1 md:py-1  md:text-2xl"
              onClick={() => setAnyDayCount(anyDayCount + 1)}
            >
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" t="1551322312294" viewBox="0 0 1024 1024" version="1.1" pId="10297" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><defs></defs><path d="M474 152m8 0l60 0q8 0 8 8l0 704q0 8-8 8l-60 0q-8 0-8-8l0-704q0-8 8-8Z" pId="10298"></path><path d="M168 474m8 0l672 0q8 0 8 8l0 60q0 8-8 8l-672 0q-8 0-8-8l0-60q0-8 8-8Z" pId="10299"></path></svg>
           
            </button>
          </div>
        </div>
          </div>
        </div>
      </div>
      </div>

      {/* Any Day Entry Ticket */}

      {/* Free Ticket */}
      <div className="bg-white rounded-2xl md:rounded-3xl text-[#20295C] p-4 md:p-8  mb-4 ">
        <div className="flex flex-col">
          <p className="text-sm font-bold md:text-xl">Free Ticket (Minor/Seniors/POD)</p>
          <div className="w-full flex justify-between">

          <span className="text-sm md:text-lg text-gray-500 mt-3">
            Children below the age of 3, Seniors above 65, and POD included
          </span>
        <div className="flex justify-between items-center mt-4">
          <div className="flex items-center space-x-2 md:space-x-3">
            <button
              className="rounded-full border border-[#F26F22] text-[#F26F22] font-medium py-1 px-3 md:px-1 md:py-1  md:text-2xl "
              onClick={() => setFreeTicketCount(Math.max(freeTicketCount - 1, 0))}
            >
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path></svg>
            </button>
            <span className="md:text-xl ">{freeTicketCount}</span>
            <button
              className="rounded-full border border-[#F26F22] text-[#F26F22] font-medium py-1 px-3 md:px-1 md:py-1  md:text-2xl "
              onClick={() => setFreeTicketCount(freeTicketCount + 1)}
            >
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" t="1551322312294" viewBox="0 0 1024 1024" version="1.1" pId="10297" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><defs></defs><path d="M474 152m8 0l60 0q8 0 8 8l0 704q0 8-8 8l-60 0q-8 0-8-8l0-704q0-8 8-8Z" pId="10298"></path><path d="M168 474m8 0l672 0q8 0 8 8l0 60q0 8-8 8l-672 0q-8 0-8-8l0-60q0-8 8-8Z" pId="10299"></path></svg>
            </button>
          </div>
        </div>
          </div>
        </div>
      </div>

      {/* Proceed to Checkout */}
      <div className="w-full flex justify-center">

      <button
        className=" px-9 active md:text-lg  bg-orange-500 text-white py-3 rounded-full min-w-52 font-bold mt-4  hover:bg-orange-600 transition"
        onClick={()=>{
          set_sbmt_btn_content(<div className="inputLoader"></div>)
          setTimeout(()=>{
            navigate('/checkout')
          },3000)
        }}
        >
        {sbmt_btn_content}
      </button>
        </div>
    </div>
  );
};

export default TicketSelection;
